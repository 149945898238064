import { useMutation, useQuery, useQueryClient } from "react-query";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  reactQueryGet,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const PROFILE_INFO_QUERY_KEY = "PROFILE_INFO";
export const useProfileInfo = ({ memberPublicId, params = {} } = {}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { refetch: refetchPunditUserContext } = usePunditUserContext();
  const queryKey = [PROFILE_INFO_QUERY_KEY, memberPublicId, params];

  const fetchProfileInfo = () =>
    reactQueryGet(internalApi.profile.show({ memberPublicId, params }));

  const getProfileInfoQuery = useQuery(queryKey, fetchProfileInfo, {
    retry: false,
    enabled: Boolean(memberPublicId),
  });

  const updateProfileInfoMutation = useMutation(
    formData =>
      reactQueryPut(
        internalApi.profile.update({
          memberPublicId,
        }),
        formData,
      ),
    {
      onSuccess: async () => {
        const currentProfileInfo = getProfileInfoQuery?.data;
        const currentLocale = currentProfileInfo?.locale;

        toast.success(I18n.t("profile_saved_successfully"));
        await refetchPunditUserContext();
        await queryClient.invalidateQueries(PROFILE_INFO_QUERY_KEY);

        const newProfileInfo = await queryClient.fetchQuery(
          queryKey,
          fetchProfileInfo,
        );
        const newLocale = newProfileInfo?.locale;

        window.webview?.onProfileInfoSaved?.();
        // reload the page if the locale has changed
        if (currentLocale !== newLocale) {
          window.location.reload();
        }
      },
      onError: err => {
        toast.error(err.message);
      },
    },
  );

  return {
    getProfileInfoQuery,
    updateProfileInfoMutation,
  };
};
