export const supportedCurrencies = {
  USD: {
    code: "USD",
    flag: "🇺🇸",
  },
  BRL: {
    code: "BRL",
    flag: "🇧🇷",
  },
} as const;

export const defaultCurrency = supportedCurrencies["USD"];
