import platform from "platform";

export const isMobileDevice = () => window.MOBILE_DEVICE;

export const getOS = () => {
  const os = ["Windows", "Linux", "Macintosh"];
  const osName = os.find(v => navigator.appVersion.indexOf(v) >= 0);
  return osName ? osName : "Other";
};

export const parseUserAgent = () => {
  const { name, version, os, description, product, layout, manufacturer, ua } =
    platform;

  // Note: making this redundant on purpose
  // to showcase available data.
  return {
    name,
    version,
    os,
    description,
    product,
    layout,
    manufacturer,
    ua,
  };
};

export const isChromiumBased = () => !!window.chrome;
export const isSafari = () => parseUserAgent().name === "Safari";
export const isIphone = () => parseUserAgent().product === "iPhone";
export const isAndroid = () => parseUserAgent().os?.family === "Android";
export const isMobileOs = () =>
  ["iOS", "Android"].includes(parseUserAgent().os?.family);

export const isIntendedOnNewTab = event => {
  const isCtrlPressed = event.ctrlKey || event.metaKey;
  const isMiddleMouseClick = event.button && event.button === 1;

  return isCtrlPressed || isMiddleMouseClick;
};
